.banner {
  border: none;
  overflow: hidden;
  color: $white;
  background: url('/img/home-banner.jpg') no-repeat bottom right;
  background-size: cover;

  @include mobile {
    background-position: center left; }


  .hero-body {
    border: none;
    @include touch {
      height: 90vh;
      padding-top: 20vh;
      padding-bottom: 6rem; }
    @include mobile {
      .title {
        font-size: 2rem; } }

    @include desktop {
      padding-top: 9rem;
      padding-bottom: 9rem; }

    .title {
      color: $yellow; }
    .button {
      margin-top: 4rem; } } }

