.our-difference {
  .title.is-heading {
    padding: 0 0 4rem;
    font-weight: normal;
    @include mobile {
      padding-bottom: 1rem; } }

  .title {
    font-weight: bolder;
    @include mobile {
      padding: .5rem 0;
      margin-bottom: .5rem; } } }

.our-difference .column {
  &:nth-of-type(odd) {
    padding: 1rem 2rem 1rem 0;
    border-right: 1px solid $grey-lighter; }

  &:nth-of-type(even) {
    padding: 1rem  0 1rem 2rem; }

  &:nth-of-type(-n+2) {
    padding-top: 0;
    border-bottom:  1px solid $grey-lighter; }

  @include mobile {
    padding: 3rem 0 0 0  !important;
    border: none !important; } }



