.about-us {
  background: linear-gradient(120deg, $grey-slate, $primary, $primary);

  .hero-body {
    padding-top: 9rem;
    padding-bottom: 7rem;
    @include mobile {
      padding-top: 7rem;
      padding-bottom: 5rem; } }


  .content {
    color: $white !important;
    a {
      text-decoration: underline; } } }

.our-team {
  .is-heading {
    padding-bottom: 3rem; }

  .columns {
    padding-top: 1rem;
    padding-bottom:  4rem; }

  .title.stripe {
    font-size: 1.2rem;
    color: $danger; }

  h5 + p {
    font-size:  0.8rem;
    color: $grey-light; } }



