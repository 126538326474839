

@import "variables";
@import "~bulma";
@import "normalize";

@import "components/all";
@import "home_page/all";
@import "about";
@import "contact_us";
@import "products";
