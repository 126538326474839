@include desktop {
  .container {
    max-width: 1152px; } }

.title {
  font-family: $family-title; }

.is-700-desktop {
  margin: 0 auto;
  max-width: 700px; }

.title.stripe {
  text-align: center;
  border-bottom: 2px solid $grey-lighter;
  line-height: 0.1em;
  font-size: 1.4rem;
  font-weight: bold;

  &.is-left {
    text-align: left; }

  &.is-red {
    color: $red; }

  &.is-yellow {
    color: $yellow; }

  span {
    background-color: $white;
    padding: 0 1rem;
    z-index: 20; } }
.is-image {
  //border-radius: 5px
 }  //box-shadow: 1px 1px 15px transparentize($grey-slate, .9)
