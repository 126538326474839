
$orange:       hsl(14,  100%, 53%) !default;
$yellow:       hsl(40, 80%, 68%) !default;
$green:        hsl(152, 59%, 46%) !default;
$turquoise:    hsl(152, 59%, 46%) !default;
$blue:         hsl(217, 71%,  53%) !default;
$purple:       hsl(271, 100%, 71%) !default;
$red:          hsl(4, 84%, 62%) !default;
$grey-slate:   hsl(250, 11%, 31%) !default;

//font families
$family-title: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;

// Text colors
$text: $grey-slate !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey-slate !default;
$text-strong: $grey-slate!default;

